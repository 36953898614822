import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "../assets/logo.png";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 20,
  },
  logo: {
    width: 100,
    marginBottom: 20,
  },
  header: {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 10,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
  },
  infoTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  infoCell: {
    width: "48%",
    padding: 10,
    border: "1px solid #000",
    fontSize: 12,
  },
  salaryTable: {
    fontSize: 12,
    width: "100%",
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 5,
  },
  totalSalary: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20,
  },
  notesSection: {
    marginTop: 20,
    fontSize: 10,
    color: "#555",
    fontStyle: "italic",
  },

  noteText: {
    marginBottom: 5,
  },

  note: {
    fontSize: 10,
    fontStyle: "italic",
    color: "#555",
    alignContent: "left",
    marginLeft: 0,
    paddingLeft: 0,
  },
});

const SalarySlipPDF = ({ employeeData, salaryData }) => {
  // Calculate salary breakdown
  const baseSalary = salaryData?.monthlyPayGross || 0;
  const overtime = salaryData?.overtimePay || 0;
  const foodAllowance = salaryData?.foodAllowance || 0;
  const lateDeduction = salaryData?.lateDeduction || 0;
  const absentDeduction = salaryData?.absentDeduction || 0;

  let totalSalary =
    baseSalary + overtime + foodAllowance - lateDeduction - absentDeduction;

  let noteIndex = 1;
  const notes = [];

  // Add debit transactions (e.g., bonuses)
  if (salaryData?.debitTransaction) {
    salaryData.debitTransaction.forEach((debit) => {
      totalSalary += debit.amount; // Add to total salary
      if (debit.note) {
        notes.push({ symbol: `*${noteIndex}`, note: debit.note });
        noteIndex++;
      }
    });
  }

  // Deduct credit transactions (e.g., deductions)
  if (salaryData?.creditTransaction) {
    salaryData.creditTransaction.forEach((credit) => {
      totalSalary -= credit.amount; // Subtract from total salary
      if (credit.note) {
        notes.push({ symbol: `*${noteIndex}`, note: credit.note });
        noteIndex++;
      }
    });
  }

  if (totalSalary < 0) totalSalary = 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Logo */}
        <View xs={3}>
          <Image src={Logo} style={styles.logo} />
        </View>

        <Text style={{ textAlign: "center", paddingBottom: 15 }}>
          SLIP GAJI KARYAWAN
        </Text>

        {/* Header */}
        <Text style={styles.header}>LIQUE STORE</Text>
        <Text style={styles.header}>
          Jl. Raya Pagedangan No.18, Kadu Sirung, Kec. Pagedangan, Tangerang,
          Banten 15336
        </Text>

        {/* Employee and Salary Info */}
        <View style={styles.infoTable}>
          <View style={styles.infoCell}>
            <Text>Nama: {employeeData?.fullname || "N/A"}</Text>
            <Text>Jabatan: {employeeData?.position || "N/A"}</Text>
            <Text>No HP: {employeeData?.phoneNumber || "N/A"}</Text>
          </View>
          <View style={styles.infoCell}>
            <Text>Email: {employeeData?.email || "N/A"}</Text>
            <Text>Bulan: {salaryData?.month || "N/A"}</Text>
            <Text>Tahun: {salaryData?.year || "N/A"}</Text>
          </View>
        </View>

        {/* Salary Breakdown */}
        <View style={styles.salaryTable}>
          {/* Row for salary data */}
          <View style={styles.row}>
            {/* Name and Note */}
            <Text style={styles.name}>Gaji Pokok</Text>
            <Text>{`Rp ${baseSalary.toLocaleString("id-ID")}`}</Text>
          </View>

          {/* Repeat for other salary items */}
          <View style={styles.row}>
            <Text style={styles.name}>Bonus Lembur</Text>
            <Text>{`Rp ${overtime.toLocaleString("id-ID")}`}</Text>
          </View>

          <View style={styles.row}></View>

          {/* (+) Section */}
          <View style={styles.row}>
            <Text>(+)</Text>
          </View>

          {/* Debit transactions */}
          {salaryData?.debitTransaction &&
            salaryData.debitTransaction.map((debit, index) => (
              <View style={styles.row} key={index}>
                <Text>
                  {debit.name}{" "}
                  {debit.note && (
                    <Text style={styles.note}>{`*`}</Text>
                  )}
                </Text>
                )}
                <Text>{`Rp ${debit.amount.toLocaleString("id-ID")}`}</Text>
              </View>
            ))}

          <View style={styles.row}></View>

          {/* (-) Section */}
          <View style={styles.row}>
            <Text>(-)</Text>
          </View>

          {/* Credit transactions */}
          {salaryData?.creditTransaction &&
            salaryData.creditTransaction.map((credit, index) => (
              <View style={styles.row} key={index}>
                <Text>
                  {credit.name}{" "}
                  {credit.note && (
                    <Text style={styles.note}>{`*`}</Text>
                  )}
                </Text>
                <Text>{`Rp ${credit.amount.toLocaleString("id-ID")}`}</Text>
              </View>
            ))}
        </View>

        {/* Notes Section */}

        {notes.length > 0 && (
          <View style={styles.notesSection}>
            <Text>Catatan:</Text>

            {notes.map((note, index) => (
              <Text style={styles.noteText} key={index}>
                {note.symbol}: {note.note}
              </Text>
            ))}
          </View>
        )}

        {/* Total Salary */}
        <Text style={styles.totalSalary}>
          TOTAL GAJI: {`Rp ${totalSalary.toLocaleString("id-ID")}`}
        </Text>
      </Page>
    </Document>
  );
};

export default SalarySlipPDF;
