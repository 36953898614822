import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import logoShow from "./assets/EyeClose.svg";
import logoHide from "./assets/EyeOpen.svg";

const containerStyle = {
  backgroundColor: "black",
  color: "white",
  borderRadius: 25,
  boxShadow: "10px 10px 5px grey",
};

const textfieldStyle = {
  input: {
    color: "white",
    border: "1px solid white",
    borderRadius: "10px",
  },
  placeholder: {
    color: "lightgray",
  },
};

const btnSubmit = {
  marginTop: 5,
  justifyContent: "center",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "3px solid black",
};

function ResetPasswordPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [step, setStep] = useState(1); // Step 1: Verification, Step 2: Reset Password

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [queryParameters] = useSearchParams();

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  useEffect(() => {
    const storedMessage = localStorage.getItem("verifySuccess");
    if (storedMessage) {
      setStep(2);
      navigate("/resetPassword/reset");
    } else if (step === 1) {
      handleVerify();
    }
  }, [step, navigate]); // Add `step` and `navigate` as dependencies
  
  const handleVerify = async () => {
    const id = queryParameters.get("id");
    const otpCode = queryParameters.get("otp");
  
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("otpCode", otpCode);
  
      const response = await axios.post(`${backendUrl}/forgotPassword/verify`, formData);
      if (response.status === 200) {
        localStorage.setItem("verifySuccess", "true");
        setEmail(response.data?.data?.email);
        setStep(2); // This will trigger the form to display
      } else {
        setShowError(true);
        setMsgError(response.data);
      }
    } catch (error) {
      setShowError(true);
      setMsgError(error.response?.data || "Verification failed");
    } finally {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };  

  const validate = () => {
    let tempErrors = {};

    if (!password) {
      tempErrors.password = "Password harus diisi";
    } else if (password.length > 255) {
      tempErrors.password = "Password maksimal berjumlah 255 karakter";
    }
    if (!confirmPassword) {
      tempErrors.confirmPassword = "Konfirmasi Password harus diisi";
    } else if (confirmPassword.length > 255) {
      tempErrors.confirmPassword =
        "Konfirmasi Password maksimal berjumlah 255 karakter";
    } else if (password !== confirmPassword) {
      tempErrors.confirmPassword =
        "Konfirmasi Password harus sama dengan password";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  if (step === 1) {
    handleVerify();
  }

  const handleResetPassword = async (e) => {
    if (validate()) {
      e.preventDefault();
      try {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        const response = await axios.post(
          `${backendUrl}/forgotPassword/reset`,
          formData
        );
        if (response.status === 200) {
          setShowSuccess(true);
          setMsgSuccess(response.data);

          setTimeout(() => {
            setShowSuccess(false);
            navigate("/login");
          }, 3000);
        } else {
          setShowError(true);
          setMsgError(response.data);
          setTimeout(() => {
            setShowError(false);
          }, 5000);
        }
      } catch (error) {
        setShowError(true);
        setMsgError(error.response?.data || "Password reset failed");
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
    }
  };

  return (
    <div>
      {step === 1 ? (
        <Container component="main" maxWidth="sm" sx={{ marginTop: 10 }}>
          {showError && (
            <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
              {msgError}
            </Alert>
          )}
        </Container>
      ) : (
        <Container component="main" maxWidth="sm" sx={{ marginTop: 10 }}>
          {showError && (
            <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
              {msgError}
            </Alert>
          )}
          {showSuccess && (
            <Alert variant="filled" severity="success" sx={{ marginBottom: 5 }}>
              {msgSuccess}
            </Alert>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              borderRadius: 5,
            }}
            style={containerStyle}
          >
            <Typography component="h1" variant="h4">
              Reset Password
            </Typography>
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs={12}>
                <TextField
                  sx={textfieldStyle}
                  className="input"
                  placeholder="Email"
                  value={email}
                  autoComplete="off"
                  fullWidth
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={textfieldStyle}
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  helperText={errors.password}
                  FormHelperTextProps={{ sx: { color: "red" } }}
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          onClick={handleClickShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={showPassword ? logoHide : logoShow}
                            alt="Toggle visibility"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={textfieldStyle}
                  placeholder="Konfirmasi Password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  helperText={errors.confirmPassword}
                  FormHelperTextProps={{ sx: { color: "red" } }}
                  fullWidth
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          onClick={handleClickShowConfirmPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={showConfirmPassword ? logoHide : logoShow}
                            alt="Toggle visibility"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={btnSubmit}
                  onClick={handleResetPassword}
                  fullWidth
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </div>
  );
}

export default ResetPasswordPage;
