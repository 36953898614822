import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import styled from "styled-components";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CssBaseline,
  Drawer,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import SupervisorSidebar from "./sidebar";
import { animated, useSpring } from "@react-spring/web";
import { AccountCircle } from "@mui/icons-material";
import { useAuth } from "../authContext";
import TableHead from "@mui/material/TableHead";
import FormControlLabel from "@mui/material/FormControlLabel";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const styleModalBesar = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  overflowY: "auto",
};

export default function DataKaryawan() {
  const drawerWidth = 300;

  const { auth, logout } = useAuth();
  const [openLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
  const [messageUpdate, setMessageUpdate] = useState("");

  const getUsername = auth.user ? auth.user.username : "";

  // Table values
  const tableHeaders = [
    { id: "fullName", label: "Nama Lengkap" },
    {
      id: "jam_masuk",
      numeric: false,
      disablePadding: false,
      label: "Jam Masuk",
    },
    {
      id: "jadwal_libur",
      numeric: false,
      disablePadding: false,
      label: "Jadwal Libur",
    },
    { id: "workingHours", label: "Jam Kerja Pokok" },
    { id: "payPerHour", label: "Gaji Per Jam" },
    { id: "paidOffDay", label: "Gaji Libur" },
    { id: "overtimePay", label: "Gaji Lembur" },
    { id: "foodAllowance", label: "Uang Makan" },
  ];
  const [employeeList, setEmployeeList] = useState([]);

  // Form values
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState({});
  const [fullName, setFullName] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [payPerHour, setPayPerHour] = useState("");
  const [paidOffDay, setPaidOffDay] = useState(false);
  const [overtimePay, setOvertimePay] = useState("");
  const [foodAllowance, setFoodAllowance] = useState("");
  const [jadwal_libur, setJadwal_libur] = useState("");
  const [entryhour, setEntryhour] = useState("");
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();

  const optHarilibur = [
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ];

  const fetchEmployeeList = async () =>
    await axios.get(`${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}`);

  // Get employee list
  useEffect(() => {
    fetchEmployeeList().then((res) => {
      console.log(res.data.employeeList);
      setEmployeeList(res.data.employeeList)});
  }, []);

  const validateEdit = () => {
    let tempErrors = {};
    if (!entryhour) {
      tempErrors.entryhour = "Jam masuk harian harus diisi";
    }
    if (!jadwal_libur || jadwal_libur.length > 10) {
      tempErrors.jadwal_libur =
        "=Jadwal libur harus diisi dan maksimal 20 karakter";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle open edit employee modal
  const handleOpenEdit = async (employeeId) => {
    const employeeData = await axios
      .get(`${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/${employeeId}`)
      .then((res) => res.data);

    setSelectedEmployeeId(employeeId);
    setFullName(employeeData.fullName);
    setJadwal_libur(employeeData.offDay);
    setEntryhour(employeeData.scheduledClockIn);
    setWorkingHours(employeeData.payDetail.workingHours);
    setPayPerHour(employeeData.payDetail.payPerHour);
    setOvertimePay(employeeData.payDetail.overtimePay);
    setFoodAllowance(employeeData.payDetail.foodAllowance);
    setPaidOffDay(employeeData.payDetail.paidOffDay);

    setOpenEdit(true);
  };

  const handleUpdateEmployee = async () => {
    if (validateEdit()) {
      try {
        await axios.put(
          `${process.env.REACT_APP_ENDPOINTS_EMPLOYEES_SERVICE}/${selectedEmployeeId}`,
          {
            scheduledClockIn: entryhour,
            jadwal_libur: jadwal_libur,
            payDetail: {
              fullName: fullName,
              workingHours: workingHours,
              payPerHour: payPerHour,
              paidOffDay: paidOffDay,
              overtimePay: overtimePay,
              foodAllowance: foodAllowance,
            },
          }
        );

        fetchEmployeeList().then((res) =>
          setEmployeeList(res.data.employeeList)
        );
        setOpenEdit(false);
      } catch (error) {
        setErrors(error.response);
        setMsgError("Gagal Ubah Data Karyawan");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
      setOpenEdit(false);
    }
  };

  const handleLogout = () => {
    setOpenLogout(false);
    logout();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          <SupervisorSidebar />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Button
          style={{ float: "right" }}
          color="inherit"
          onClick={handleOpenLogout}
          startIcon={<AccountCircle />}
        >
          {getUsername}
        </Button>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openLogout}
          onClose={handleCloseLogout}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openLogout}>
            <Box sx={styleModal}>
              <AccountCircle style={{ fontSize: 100 }} />
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah anda yakin ingin keluar?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={handleLogout}>
                  Ya
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseLogout}
                  sx={{ ml: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Tidak
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <br></br>
        <Toolbar />
        <RootContainer>
          {showSuccessUpdate && (
            <Alert
              variant="filled"
              severity="success"
              style={{ marginTop: 20, backgroundColor: "#1B9755" }}
            >
              {messageUpdate}
            </Alert>
          )}

          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((headers) => (
                        <TableCell
                          key={headers.id}
                          align={"center"}
                          sx={{ fontWeight: "bold" }}
                        >
                          {headers.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {employeeList &&
                      employeeList.map((employee) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={employee.id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell align="center">
                              {employee.fullName}
                            </TableCell>
                            <TableCell align="center">
                              {employee.scheduledClockIn}
                            </TableCell>
                            <TableCell align="center">
                              {employee.offDay}
                            </TableCell>
                            <TableCell align="center">
                              {employee.payDetail.workingHours}
                            </TableCell>
                            <TableCell align="center">
                              {employee.payDetail.payPerHour}
                            </TableCell>
                            <TableCell align="center">
                              {employee.payDetail.paidOffDay ? "YA" : "TIDAK"}
                            </TableCell>
                            <TableCell align="center">
                              {employee.payDetail.overtimePay}
                            </TableCell>
                            <TableCell align="center">
                              {employee.payDetail.foodAllowance}
                            </TableCell>

                            {/* Edit employee button */}
                            <TableCell sx={{ display: "flex" }}>
                              <Tooltip title="edit">
                                <IconButton
                                  onClick={() => handleOpenEdit(employee.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* ini modal edit data karyawan */}
              <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    TransitionComponent: Fade,
                  },
                }}
              >
                <Fade in={openEdit}>
                  <Box sx={styleModalBesar}>
                    <form>
                      <Grid container spacing={3}>
                        {/* Full Name */}
                        <Grid item xs={12}>
                          <Typography variant={"h5"}>{fullName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Jam Masuk Harian *</Typography>
                          <TextField
                            fullWidth
                            type="time"
                            value={entryhour}
                            error={!!errors.entryhour}
                            onChange={(e) => setEntryhour(e.target.value)}
                            onFocus={(e) =>
                              e.target.showPicker && e.target.showPicker()
                            }
                            sx={"color-scheme: dark;"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Jadwal Libur *</Typography>
                          <Autocomplete
                            fullWidth
                            options={optHarilibur}
                            value={jadwal_libur}
                            renderInput={(params) => <TextField {...params} />}
                            error={!!errors.jadwal_libur}
                            onChange={(event, value) => setJadwal_libur(value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            type={"number"}
                            label={"Jam Kerja Pokok"}
                            value={workingHours}
                            onChange={(e) => setWorkingHours(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            type={"number"}
                            label={"Gaji Per Jam"}
                            value={payPerHour}
                            onChange={(e) => setPayPerHour(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} alignItems="center">
                          <FormControlLabel
                            control={<Checkbox />}
                            labelPlacement={"start"}
                            label={"Gaji Hari Libur"}
                            checked={paidOffDay}
                            onChange={(e) => setPaidOffDay(e.target.checked)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            type={"number"}
                            label={"Gaji Lembur"}
                            value={overtimePay}
                            onChange={(e) => setOvertimePay(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            type={"number"}
                            label={"Uang Makan"}
                            value={foodAllowance}
                            onChange={(e) => setFoodAllowance(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            onClick={handleUpdateEmployee}
                            fullWidth
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Fade>
              </Modal>
            </Paper>
          </Box>
        </RootContainer>
      </Box>
    </Box>
  );
}
